import { useIntl, FormattedMessage } from "react-intl";
import Button from "@pagepro-monorepo/ui/lib/components/atoms/Button";
import { FlexBox } from "@pagepro-monorepo/ui/lib/components/styles/Grid";

import Checkbox from "@components/atoms/fields/Checkbox";

import { useNewsletterForm } from "./hooks";
import { NewsletterFormProps } from "./types";
import * as Styled from "./styles";
import Modal from "./Partials/Modal";

const NewsletterForm: React.FC<NewsletterFormProps> = ({
  buttonLabel,
  agreement,
  sendgridId,
  successMessage
}) => {
  const { formatMessage } = useIntl();
  const {
    response,
    isLoading,
    formRef,
    emailRef,
    errors,
    handleResetForm,
    handleOnSubmit,
    handleOnChange
  } = useNewsletterForm(sendgridId);

  return (
    <>
      <Styled.Wrapper
        aria-label="Newsletter form"
        className={response?.success ? "is-newsletter-form-sent" : ""}
      >
        <form ref={formRef} method="POST" onSubmit={handleOnSubmit}>
          <FlexBox flexDirection="column" gap="0.75rem" width="100%">
            <Styled.FieldWrapper isValid={!errors.email}>
              <Styled.InputField
                ref={emailRef}
                name="email"
                type="email"
                label={formatMessage({
                  defaultMessage: "Your email address"
                })}
                required
                className={
                  errors.email ? "is-newsletter-form-email-error" : ""
                }
                onChange={handleOnChange}
              />
            </Styled.FieldWrapper>
            {agreement && (
              <FlexBox maxWidth={["none", 440]}>
                <Checkbox required>{agreement}</Checkbox>
              </FlexBox>
            )}
            <Button disabled={isLoading} type="submit">
              {buttonLabel || (
                <FormattedMessage defaultMessage="Sign up" />
              )}
            </Button>
          </FlexBox>
        </form>
      </Styled.Wrapper>
      {response && (
        <Modal
          onClose={handleResetForm}
          status={
            response.success && !response.error ? "success" : "error"
          }
          {...{ successMessage }}
        />
      )}
    </>
  );
};

export default NewsletterForm;
