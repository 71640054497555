import React from "react";
import {
  StoryblokComponent,
  storyblokEditable
} from "@storyblok/react";

import NewsletterForm from "@components/organisms/forms/NewsletterForm";

import { NewsletterFormStoryblokProps } from "./types";

const NewsletterFormStoryblok: React.FC<
  NewsletterFormStoryblokProps
> = ({ blok }) => {
  const {
    _uid: key,
    buttonLabel,
    agreement,
    sendgridId,
    successMessage
  } = blok;

  return (
    <NewsletterForm
      {...{
        key,
        buttonLabel,
        sendgridId,
        agreement: agreement?.[0] ? (
          <StoryblokComponent blok={agreement?.[0]} />
        ) : undefined,
        successMessage: successMessage?.[0] ? (
          <StoryblokComponent blok={successMessage?.[0]} />
        ) : undefined,
        ...storyblokEditable(blok)
      }}
    />
  );
};

export default NewsletterFormStoryblok;
