import { useState, useRef, useCallback, FormEvent } from "react";

import { API_URLS } from "../../../../api/consts";
import { errorMap } from "../consts";

import {
  FormDataProps,
  FormDataErrorProps,
  NewsletterApiResponse
} from "./types";
import { initialErrors } from "./consts";

export const useNewsletterForm = (sendgridId?: string) => {
  const [response, setResponse] = useState<NewsletterApiResponse>();
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] =
    useState<FormDataErrorProps>(initialErrors);
  const formRef = useRef<HTMLFormElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);

  const handleValidate = ({ email }: FormDataProps) => {
    const validatedErrors = { ...initialErrors };

    const { required, invalidEmail } = errorMap;

    if (email.length === 0) {
      validatedErrors.email = required;
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      validatedErrors.email = invalidEmail;
    }

    return validatedErrors;
  };

  const handleResetForm = useCallback(() => {
    setErrors(initialErrors);
    setResponse(undefined);

    if (!formRef.current) return;

    formRef.current?.reset();
  }, []);

  const handleSendForm = async (
    values: FormDataProps
  ): Promise<NewsletterApiResponse> => {
    const { email } = values;

    const res = await fetch(API_URLS.newsletter, {
      body: JSON.stringify({
        email,
        sendgridId
      }),
      headers: {
        "Content-Type": "application/json"
      },
      method: "PUT"
    });

    return res.json();
  };

  const handleOnChange = () => {
    const values = {
      email: emailRef.current?.value || ""
    };

    setErrors(handleValidate(values));
  };

  const handleOnSubmit = async (
    event: FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    if (errors.email) return;

    setResponse(undefined);
    setIsLoading(true);

    const values = {
      email: emailRef.current?.value || ""
    };

    setResponse(await handleSendForm(values));

    setIsLoading(false);
  };

  return {
    response,
    isLoading,
    formRef,
    emailRef,
    errors,
    handleResetForm,
    handleOnSubmit,
    handleOnChange
  };
};
