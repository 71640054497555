import styled, { css } from "styled-components";
import media from "@pagepro-monorepo/ui/lib/theme/config/media";

import Input from "@components/atoms/fields/Input";

import { StyledFieldWrapperProps } from "./types";

export const Wrapper = styled.div`
  max-width: 28rem;
  margin: 0 auto;

  @media ${media.tablet} {
    margin-right: 0;
  }
`;

export const InputField = styled(Input)``;

export const FieldWrapper = styled.div<StyledFieldWrapperProps>`
  position: relative;

  ${({ isValid }) =>
    !isValid &&
    css`
      color: ${({ theme }) => theme.colors.accentDecor};

      ${InputField} {
        color: ${({ theme }) => theme.colors.accentDecor};
        border-bottom: 1px solid
          ${({ theme }) => theme.colors.accentDecor};
      }
    `}
`;

export const Error = styled.span`
  display: inline-block;
  font-size: 0.75rem;
  line-height: 2.5;
  margin-left: 0.75rem;
`;
