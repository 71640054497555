import React from "react";
import { Button } from "@pagepro-monorepo/ui";
import { FormattedMessage } from "react-intl";
import Typography from "@pagepro-monorepo/ui/lib/components/styles/Typography";

import MessageSent from "@assets/svg/message-sent.svg";
import CloseIcon from "@assets/svg/close.svg";

import * as Styled from "./styles";
import { ModalProps } from "./types";

const Modal = ({ status, successMessage, onClose }: ModalProps) => (
  <Styled.ModalWrapper>
    <Styled.Modal className="ga-newsletter-signup">
      {status === "success" ? (
        <>
          <MessageSent />
          {successMessage ?? (
            <Typography variant="body3" textAlign="center">
              <FormattedMessage defaultMessage="Success!" />
            </Typography>
          )}
        </>
      ) : (
        <>
          <CloseIcon width="9rem" height="9rem" />
          <Typography variant="body3" textAlign="center">
            <FormattedMessage defaultMessage="An unexpected error occurred" />
          </Typography>
        </>
      )}
      <Button width="100%" onClick={onClose}>
        <FormattedMessage defaultMessage="Close" />
      </Button>
    </Styled.Modal>
  </Styled.ModalWrapper>
);

export default Modal;
