import { FlexBox } from "@pagepro-monorepo/ui/lib/components/styles/Grid";
import styled from "styled-components";

export const ModalWrapper = styled(FlexBox).attrs({
  justifyContent: "center",
  alignItems: "center",
  width: "100vw",
  height: "100vh"
})`
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 10;
`;

export const Modal = styled(FlexBox).attrs({
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "25.625rem",
  height: "31.25rem",
  p: "5rem"
})`
  background-color: ${({ theme }) => theme.colors.white};
  gap: 2rem;
  box-shadow: 0 0.25rem 3.125rem rgba(0, 0, 0, 0.15);
`;
